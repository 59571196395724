<template>
  <v-card flat v-if="feedbackVars">
    <v-card-text v-if="feedbackVars.length">
      <v-card
        class="mb-3"
        v-for="(variavel, index) in feedbackVars"
        :key="index"
        outlined
      >
        <v-card-title class="body-2 lightgray py-2 px-3">
          {{ index + 1 }}. {{ variavel.campo }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-3">
          <v-text-field
            v-if="variavel.tipo === 'text'"
            v-model="variavel.valor"
            placeholder="Valor"
            dense
            hide-details
            @click:append.stop="updateVar(variavel)"
            @focus="variavel.focus = true"
            @blur="variavel.focus = false"
            :append-icon="variavel.focus ? 'save' : ''"
          ></v-text-field>
          <v-textarea
            v-if="variavel.tipo === 'textarea'"
            v-model="variavel.valor"
            placeholder="Valor"
            auto-grow
            dense
            hide-details
            @click:append.stop="updateVar(variavel)"
            @focus="variavel.focus = true"
            @blur="variavel.focus = false"
            :append-icon="variavel.focus ? 'save' : ''"
          ></v-textarea>
        </v-card-text>
      </v-card>
    </v-card-text>
  </v-card>
  <Carregando v-else />
</template>

<script>
import { all } from "@/services/api/tocsconfig.api.js";
import { mapActions } from "vuex";
export default {
  name: "Feedback",
  data() {
    return {
      feedbackVars: null,
    };
  },
  methods: {
    ...mapActions("Tocsconfig", {
      putConfig: "putConfig",
    }),
    getFeedbackVars() {
      this.$Progress.start();
      all("?grupo=feedback")
        .then((response) => {
          response.forEach((item) => {
            item.focus = false;
          });
          this.feedbackVars = response;
          this.$Progress.finish();
        })
        .catch((error) => {
          console.log(error);
          this.$Progress.fail();
        });
    },
    updateVar(variavel) {
      delete variavel.focus;
      this.putConfig({ id: variavel.id, config: variavel });
    },
  },
  mounted() {
    this.getFeedbackVars();
  },
};
</script>

<style></style>
